
import Carousel from './Carousel'

import './Intro.scss'

export default function Intro() {
    return (
        <section className="intro" id="intro">
            <div className="welcome-card">
                <div className="welcome">
                    Bienvenue à
                </div>
                <div className="title">
                    <span>La cabane</span>
                    <span>Restaurant d'altitude</span>
                </div>
                <Carousel />
                <div className="title mt-2">
                <span></span>
                    <span>Spécialités de Montagne</span>
                </div>
            </div>

        </section>
    )
}