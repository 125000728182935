import Carousel from 'react-bootstrap/Carousel';

import cabane_0 from '../images/lacabane.jpg'
import cabane_1 from '../images/cabane_1.jpg'
import cabane_2 from '../images/cabane_2.jpg'
import cabane_3 from '../images/cabane_3.jpg'
import cabane_4 from '../images/cabane_4.jpg'
import cabane_5 from '../images/cabane_5.jpg'
import cabane_6 from '../images/cabane_6.jpg'

import './Carousel.css'

function LCCarousel() {
  return (
    <div className='mcarousel justify-content-center'>
    <Carousel fade>
    <Carousel.Item>
      <div className='img_carousel'>
        <img
          src={cabane_0}
          alt="First slide"
        />
        </div>
      </Carousel.Item>
      <Carousel.Item>
      <div className='img_carousel'>
        <img
          src={cabane_1}
          alt="First slide"
        />
        </div>
      </Carousel.Item>
      <Carousel.Item>
      <div className='img_carousel'>
        <img
          src={cabane_2}
          alt="First slide"
        />
        </div>
      </Carousel.Item>
      <Carousel.Item>
      <div className='img_carousel'>
        <img
          src={cabane_3}
          alt="First slide"
        />
        </div>
      </Carousel.Item>
      <Carousel.Item>
      <div className='img_carousel'>
        <img
          src={cabane_4}
          alt="First slide"
        />
        </div>
      </Carousel.Item>
      <Carousel.Item>
      <div className='img_carousel'>
        <img
          src={cabane_5}
          alt="First slide"
        />
        </div>
      </Carousel.Item>
      <Carousel.Item>
      <div className='img_carousel'>
        <img
          src={cabane_6}
          alt="First slide"
        />
        </div>
      </Carousel.Item>
    </Carousel>
    </div>

  );
}

export default LCCarousel;