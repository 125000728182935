import React from 'react';
import './FoodCard.css'

export default function FoodCard({ source, mealType, title, description, price }) {
    return (
        <div className="foodcard">
            {source ?
                <div className="foodcard-image">
                    <img src={source} alt=""/>
                </div> : <div className='food-noimage'></div>
            }

            <div className="foodcard-text">
                <p className="foodcard-meal-type">{mealType}</p>
                <h2 className="foodcard-title">{title}</h2>
                <p className="foodcard-body">{description}</p>
            </div>
            <div className="foodcard-price">{price} €</div>
        </div>
    )
}