import './Dishes.css'

export default function Dishes({ title, description, price }) {
    return (
        <div className='row row_dishes'>
            <div className='col'>
                <div className='dishes'>{title}</div>
                <div className='desc_dishes'>{description}</div>
                <div className='line'></div>
            </div>
            {price && <div className='col price'>{price} €</div>}
        </div>
    )
}