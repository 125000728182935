import Header from '../components/Header'
import Intro from '../components/Intro'
import Menu from '../components/Menu'
import Contact from '../components/Contact'


export default function Home() {
    return (
        <main className='main'>
            <Header className="is-hidden"/>
            <Intro />
            <Menu />
            <Contact id="contact"/>
        </main>
    )
}