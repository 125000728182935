import Header from "../components/Header"
import packageJson from '../../package.json';

export default function TermOfService() {
    return (
        <main className='main'>
            <Header />
            <div className="blur-card mb-2">
                <h2>Mentions légales</h2>
                <div className="disclaimer">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="text-center">
                                        <h2>En vigueur au 01/05/2023</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="paragraph">
                                    <p>Conformément aux dispositions des Articles 6-III et 19 de la Loi n°2004-575 du 21 juin
                                        2004 pour la Confiance dans l'économie numérique, dite L.C.E.N, il est porté à la
                                        connaissance des utilisateurs et visiteurs, ci-après l'"Utilisateur", du site
                                        leregalivou.nicebiz.fr, ci-après le "Site", les présentes mentions légales.</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="paragraph">
                                    <p>La connexion et la navigation sur le Site par l'Utilisateur implique acceptation
                                        intégrale et sans réserve des présentes mentions légales.</p>
                                    <p>Ces dernières sont accessibles sur le Site à la rubrique `Mentions légales`.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="paragraph">
                                    <h3>ARTICLE 1 - L'EDITEUR</h3>
                                    <p>L'édition et la direction de la publication du Site est assurée par Mr POIROT Benoit,
                                        domicilié 190 ROUTE DE TURIN 06300 NICE et l'adresse e-mail benoit.poirot@nicebiz.fr</p>
                                    <p>ci-après l'"Editeur".</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="paragraph">
                                    <h3>ARTICLE 2 - L'HEBERGEUR</h3>
                                    <p>L'hébergeur du Site est Mr POIROT Benoit, domicilié 190 ROUTE DE TURIN 06300 NICE et
                                        l'adresse e-mail benoit.poirot@nicebiz.fr</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="paragraph">
                                    <h3>ARTICLE 3 - ACCES AU SITE</h3>
                                    <p>Le Site est accessible en tout endroit, 7j/7, 24h/24 sauf en cas de force majeure,
                                        interruption programmée ou non et pouvant découler d'une nécéssité de maintenance. En
                                        cas de modification, interruption ou suspension du Site, l'Editeur ne saurait être tenu
                                        responsable.</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="paragraph">
                                    <h3>ARTICLE 4 - CONDITIONS D'UTILISATIONS DU SITE</h3>
                                    <p>Toute utilisation, reproduction, diffusion, commercialisation, modification de toute ou
                                        partie du Site, sans autorisation de l'Editeur est prohibée et pourra entraîner des
                                        actions et poursuites judiciaires telles que notamment prévues par le Code de la
                                        propriété intellectuelle et le Code civil.</p>
                                    <p>version: {packageJson.version}</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="paragraph">
                                    <h3>ARTICLE 5 - GESTION DES COOKIES</h3>
                                    <p>Un « cookie » est un petit fichier d’information envoyé sur le navigateur de
                                        l’Utilisateur et enregistré au sein du terminal de l’Utilisateur (ex : ordinateur,
                                        smartphone), (ci-après « Cookies »). Ce fichier comprend des informations telles que le
                                        nom de domaine de l’Utilisateur, le fournisseur d’accès Internet de l’Utilisateur, le
                                        système d’exploitation de l’Utilisateur, ainsi que la date et l’heure d’accès. Les
                                        Cookies ne risquent en aucun cas d’endommager le terminal de l’Utilisateur.
                                        Le site est susceptible de traiter les informations de l’Utilisateur concernant
                                        sa visite du Site, telles que les pages consultées, les recherches effectuées. Ces
                                        informations permettent au site d’améliorer le contenu du Site, de la
                                        navigation de l’Utilisateur.
                                    </p>
                                    <p>
                                        Les Cookies facilitant la navigation et/ou la fourniture des services proposés par le
                                        Site, l’Utilisateur peut configurer son navigateur pour qu’il lui permette de décider
                                        s’il souhaite ou non les accepter de manière à ce que des Cookies soient enregistrés
                                        dans le terminal ou, au contraire, qu’ils soient rejetés, soit systématiquement, soit
                                        selon leur émetteur. L’Utilisateur peut également configurer son logiciel de navigation
                                        de manière à ce que l’acceptation ou le refus des Cookies lui soient proposés
                                        ponctuellement, avant qu’un Cookie soit susceptible d’être enregistré dans son terminal.
                                        Le site informe l’Utilisateur que, dans ce cas, il se peut que les
                                        fonctionnalités de son logiciel de navigation ne soient pas toutes disponibles.
                                        Si l’Utilisateur refuse l’enregistrement de Cookies dans son terminal ou son navigateur,
                                        ou si l’Utilisateur supprime ceux qui y sont enregistrés, l’Utilisateur est informé que
                                        sa navigation et son expérience sur le Site peuvent être limitées. Cela pourrait
                                        également être le cas lorsque Le site ou l’un de ses prestataires ne peut pas
                                        reconnaître, à des fins de compatibilité technique, le type de navigateur utilisé par le
                                        terminal, les paramètres de langue et d’affichage ou le pays depuis lequel le terminal
                                        semble connecté à Internet.
                                    </p>
                                    <p>
                                        Le cas échéant, Le site décline toute responsabilité pour les conséquences liées
                                        au fonctionnement dégradé du Site et des services éventuellement proposés par le site, résultant (i) du refus de Cookies par l’Utilisateur (ii) de l’impossibilité pour
                                        Le site d’enregistrer ou de consulter les Cookies nécessaires à leur
                                        fonctionnement du fait du choix de l’Utilisateur. Pour la gestion des Cookies et des
                                        choix de l’Utilisateur, la configuration de chaque navigateur est différente. Elle est
                                        décrite dans le menu d’aide du navigateur, qui permettra de savoir de quelle manière
                                        l’Utilisateur peut modifier ses souhaits en matière de Cookies.
                                        À tout moment, l’Utilisateur peut faire le choix d’exprimer et de modifier ses souhaits
                                        en matière de Cookies. Le site pourra en outre faire appel aux
                                        services de prestataires externes pour l’aider à recueillir et traiter les informations
                                        décrites dans cette section.
                                    </p>
                                    <p>
                                        Enfin, en cliquant sur les icônes dédiées aux réseaux sociaux Twitter, Facebook,
                                        Linkedin et Google Plus figurant sur le Site de Le site ou dans son application
                                        mobile et si l’Utilisateur a accepté le dépôt de cookies en poursuivant sa navigation
                                        sur le Site Internet ou l’application mobile du site, Twitter, Facebook,
                                        Linkedin et Google Plus peuvent également déposer des cookies sur vos terminaux
                                        (ordinateur, tablette, téléphone portable).
                                        Ces types de cookies ne sont déposés sur vos terminaux qu’à condition que vous y
                                        consentiez, en continuant votre navigation sur le Site Internet ou l’application mobile
                                        de Le site. À tout moment, l’Utilisateur peut néanmoins revenir sur son
                                        consentement à ce que Le site dépose ce type de cookies.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}